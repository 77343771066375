import { SVGProps } from 'react';

export const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 4C12.3787 4 12.6857 4.307 12.6857 4.68571V11.3143H19.3143C19.693 11.3143 20 11.6213 20 12C20 12.3787 19.693 12.6857 19.3143 12.6857H12.6857V19.3143C12.6857 19.693 12.3787 20 12 20C11.6213 20 11.3143 19.693 11.3143 19.3143V12.6857H4.68571C4.307 12.6857 4 12.3787 4 12C4 11.6213 4.307 11.3143 4.68571 11.3143H11.3143V4.68571C11.3143 4.307 11.6213 4 12 4Z" />
  </svg>
);
