import { SVGProps } from 'react';

export const SvgCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.301262 0.301262C0.702944 -0.100421 1.3542 -0.100421 1.75588 0.301262L12 10.5454L22.2441 0.301262C22.6458 -0.100421 23.2971 -0.100421 23.6987 0.301262C24.1004 0.702944 24.1004 1.3542 23.6987 1.75588L13.4546 12L23.6987 22.2441C24.1004 22.6458 24.1004 23.2971 23.6987 23.6987C23.2971 24.1004 22.6458 24.1004 22.2441 23.6987L12 13.4546L1.75588 23.6987C1.3542 24.1004 0.702944 24.1004 0.301262 23.6987C-0.100421 23.2971 -0.100421 22.6458 0.301262 22.2441L10.5454 12L0.301262 1.75588C-0.100421 1.3542 -0.100421 0.702944 0.301262 0.301262Z"
    />
  </svg>
);
