import { SVGProps } from 'react';

export const SvgArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7535 3.22671C15.4645 2.92998 14.9896 2.92372 14.6929 3.21272C14.3962 3.50172 14.3899 3.97655 14.6789 4.27329L21.4724 11.2484H0.75C0.335786 11.2484 0 11.5842 0 11.9984C0 12.4127 0.335786 12.7484 0.75 12.7484H21.4724L14.6789 19.7236C14.3899 20.0204 14.3962 20.4952 14.6929 20.7842C14.9896 21.0732 15.4645 21.0669 15.7535 20.7702L23.7871 12.5217C24.0707 12.2305 24.0707 11.7664 23.7871 11.4752L15.7535 3.22671Z"
    />
  </svg>
);
