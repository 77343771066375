import { SVGProps } from 'react';

export const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={24}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 26 24"
    {...props}
  >
    <path d="M1 6H25" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1 18H25" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
